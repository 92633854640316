.offcanvas-tab {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 2050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: var(--prime-one);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
}

.close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 22px;
    box-shadow: none;
}
